@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";

.container-fluid.top-container {
	background-color: #F2F4F3;
}

.container.slider-holder {
	gap: 18px;
	max-width: 1110px;
	padding-right: 0;
  padding-left: 0;
  @include media-breakpoint-down(md) {
  	flex-direction: column;;
  }
	.main-slider {
		flex: 0 0 75%;
		width: 75%;
		@include media-breakpoint-down(md) {
			flex: 0 1 auto;
			width: 100%;
		}
		.swiper {
			width: 100%;
			.swiper-pagination-bullet-active {
				background-color: #2D2D2D;
			}
			.swiper-wrapper {
				.swiper-slide {
					height: 350px;
					position: relative;
					background-size: cover;
					background-position: center;
					padding: 20px 40px;
					background-image: url("/images/home/equipment_slide.jpg");
					display: flex;
					flex-direction: column;
					justify-content: center;
					overflow: hidden;
					@include media-breakpoint-down(md) {
						height: auto;
						padding: 40px 20px;
					}
					&#irrigation-slide {
						background-image: url("/images/home/irrigation_slide.jpg");
					}
					&#outdoor-slide {
						background-image: url("/images/home/outdoor-living-banner.jpg");
					}
					&#equipment-slide {
						background-image: url("/images/home/power-equipment.jpg");
					}
					&#landscaping-slide {
						background-image: url("/images/home/landscaping-banner.jpg");
					}
					.overlay {
						background-color: rgba(255,255,255,0.7);
					}
					h2 {
						position: relative;
						z-index: 2;
						text-transform: uppercase;
						font-size: 1.5em;
					}
					p {
						position: relative;
						z-index: 2;
					}
				}
			}
		}
	}
	.rewards {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fff;
		@include media-breakpoint-down(md) {
			padding: 20px;
		}
		img {
			display: block;
			width: 100%;
			margin: 0 auto;
			@include media-breakpoint-down(md) {
				height: 220px;
				width: auto;
			}
		}
	}
}

.container.featured-products {
	max-width: 1140px;
  padding-right: var(--bs-gutter-x, 1rem);
  padding-left: var(--bs-gutter-x, 1rem);
	.d-flex {
		gap: 18px;
		@include media-breakpoint-down(md) {
			flex-direction: column;
		}
	}
	.product {
		flex: 1;
		&:nth-of-type(1) > a .text-wrap {
			border-color: $green;
		}
		&:nth-of-type(1) > a:hover .text-wrap h4 {
			color: $green;
		}
		&:nth-of-type(2) > a .text-wrap {
			border-color: $blue;
		}
		&:nth-of-type(2) > a:hover .text-wrap h4 {
			color: $blue;
		}
		&:nth-of-type(3) > a .text-wrap {
			border-color: $yellow;
		}
		&:nth-of-type(3) > a:hover .text-wrap h4 {
			color: $yellow;
		}
		& > a {
			display: block;
			.image-holder {
				background-color: #F2F4F3;
				padding: 20px;
				img {
					display: block;
					height: 240px;
					margin: 0 auto;
					object-fit: contain;
					object-position: center;
					@include media-breakpoint-down(md) {
						height: 200px;
					}
				}
			}
			&:hover .text-wrap h4 {
				color: $red;
			}
			.text-wrap {
				background-color: #2D2D2D;
				color: #fff;
				padding: 20px;
				border-left: 10px solid $red;
				min-height: 158px;
				h4 {
					font-size: 1.1em;
					text-transform: uppercase;
				}
				h6 {
					font-size: 0.92em;
					margin-bottom: 4px;
				}
				p {
					font-size: 0.8em;
					margin: 0;
					line-height: 1.05em;
				}
			}
		}
	}
}

.container.turfgro-ad {
	max-width: 1140px;
  padding-right: var(--bs-gutter-x, 1rem);
  padding-left: var(--bs-gutter-x, 1rem);
	a {
		display: block;
		img {
			display: block;
			width: 100%;
		}
	}
}

.container.home-features {
	max-width: 1140px;
  padding-right: var(--bs-gutter-x, 1rem);
  padding-left: var(--bs-gutter-x, 1rem);
  .d-flex {
  	gap: 18px;
  	@include media-breakpoint-down(md) {
			flex-direction: column;
		}
  	&.top {
  		margin-bottom: 18px;
  	}
  	.feature {
  		display: block;
  		position: relative;
  		flex: 1;
  		height: 250px;
  		background-size: cover;
  		background-position: center;
  		@include media-breakpoint-down(md) {
				height: auto;
				flex: 0 1 auto;
			}
  		.overlay {
  			width: 15%;
  			opacity: 0.5;
  			&.red { background-color: $red; }
  			&.blue { background-color: $blue; }
  			&.green { background-color: $green; }
  			&.yellow { background-color: $yellow; }
			&.black { background-color: $black; }
  		}
  		.text {
  			padding: 20px 20px 20px calc(15% + 25px);
  			color: #fff;
  			background: linear-gradient(180deg, rgba(0,0,0,0.7), rgba(0,0,0,0.3));
    		height: 100%;
    		@include media-breakpoint-down(md) {
					font-size: 14px;
				}
    		h3, h4, h5 {
    			text-shadow: 1px 1px 0.1em rgba(0,0,0,0.2);
    		}
			h2 {
				left: 0;
  				position: relative;
  				transition: left 300ms ease;

				font-size: calc(1.7rem + 0.9vw);
				letter-spacing: 2px;
			} 
  			h3 {
  				left: 0;
  				position: relative;
  				transition: left 300ms ease;
  			}
			h4, h5 {
  				font-weight: 400;

				left: 0;
				position: relative;
				transition: left 300ms ease;
  			}

			&.flex {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}

  		}
  	}

	a.feature {
		&:hover .text h3, &:hover .text h4, &:hover .text h2 {
			left: 20px;
		}
	}


  }
}






